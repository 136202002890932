<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveRegComercial()"
        >
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model.trim="rgNombre"
                type="text"
                label="Nombre"
                dense
                outlined
                :rules="
                  rules.required.concat([
                    rules.maxLength(rgNombre, 50),
                    rules.requiredTrim(rgNombre),
                  ])
                "
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model.trim="rgDescrip"
                label="Descripción de regla comercial"
                dense
                outlined
                :rules="
                  rules.required.concat([
                    rules.maxLength(rgDescrip, 50),
                    rules.requiredTrim(rgDescrip),
                  ])
                "
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-select
                v-model="rgProcSelect"
                :items="rgProcCombo"
                item-text="rgProcDescripGral"
                item-value="id"
                label="Procedimiento"
                outlined
                clearable
                dense
                return-object
                @input="permitirAcumular(rgProcSelect)"
                @change="
                  rgProcSelect
                    ? ((descProcNombre = rgProcSelect.rgProcDescripGral),
                      resetParametro())
                    : (descProcNombre = null)
                "
                :rules="rules.required"
                @blur="obtenerVariable()"
              >
              </v-select>
            </v-col>
          </v-row>
          <p v-if="rgProcSelect">
            <i>
              <span style="font-size: 12px">
                Descripción general del procedimiento disponible seleccionado:
                {{ descProcNombre }}</span
              ></i
            >
          </p>
          <v-row>
            <v-col cols="12" class="pb-0">
              <p v-if="rgProcSelect">
                <v-row>
                  <v-col cols="5" sm="5" md="5" class="py-0">
                    <v-text-field
                      outlined
                      type="text"
                      dense
                      ref="parametros"
                      label="Parámetros"
                      v-model="parametros"
                      :items="rgProcCombo"
                      item-text="value"
                      clearable
                      :rules="
                        rules.required.concat([
                          rules.maxLength(parametros, 150),
                        ])
                      "
                      :placeholder="`Ej. ${
                        rgProcSelect != null
                          ? rgProcSelect.rgProcEjemploParametros
                          : ''
                      }`"
                      :hint="`Ej. ${
                        rgProcSelect != null
                          ? rgProcSelect.rgProcEjemploParametros
                          : ''
                      }`"
                      persistent-hint
                    ></v-text-field>
                  </v-col>
                  <v-col cols="1" class="pa-0 mt-1">
                    <v-tooltip right max-width="40%">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">
                          {{ infoIcon }}
                        </v-icon>
                      </template>
                      <span style="white-space: pre-line">
                        Ingrese los parámetros correspondientes en el siguiente
                        orden:
                      </span>
                      <ol>
                        <ul>
                          <li v-if="rgProcSelect != null">
                            {{ rgProcSelect.rgProcDescripTotal }}
                          </li>
                        </ul>
                      </ol>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="5" class="py-0">
                    <v-select
                      v-if="
                        rgProcSelect &&
                        !rgProcSelect.rgProcPermiteEscalarPorcentaje
                      "
                      v-model="variableSelect"
                      :items="listVariables"
                      item-text="value"
                      item-value="id"
                      label="Variable"
                      outlined
                      clearable
                      dense
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="1" class="pa-0 mt-1">
                    <v-tooltip right max-width="40%">
                      <template
                        v-if="
                          rgProcSelect &&
                          !rgProcSelect.rgProcPermiteEscalarPorcentaje
                        "
                        v-slot:activator="{ on, attrs }"
                      >
                        <v-icon v-bind="attrs" v-on="on">
                          {{ infoIcon }}
                        </v-icon>
                      </template>
                      <span>
                        La variable prevalecerá sobre los parámetros para el
                        período indicado.
                      </span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="6" class="pb-0">
              <v-text-field
                v-model="periodoDesde"
                label="Período desde"
                dense
                outlined
                hint="Formato AAAAMM"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                v-mask="'######'"
                :rules="
                  rules.required.concat(
                    [rules.validDateRange(periodoDesde, periodoHasta)],
                    rules.periodoYyyyMm
                  )
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="pb-0">
              <v-text-field
                v-model="periodoHasta"
                label="Período hasta"
                dense
                hint="Formato AAAAMM"
                outlined
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                v-mask="'######'"
                :rules="
                  rules.required.concat(
                    [rules.validDateRange(periodoDesde, periodoHasta)],
                    rules.periodoYyyyMm
                  )
                "
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="6" class="py-0">
              <v-text-field
                v-model="orden"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                label="Orden"
                dense
                outlined
                :rules="rules.positiveNumber"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="py-0">
              <v-switch
                class="p-0"
                v-model="acumulable"
                label="Acumulable"
                dense
                outlined
                :disabled="disabledAcumulableSwitch"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row
            class="py-0 pl-4"
            v-if="rgProcSelect && rgProcSelect.rgProcPermiteEscalarPorcentaje"
          >
            <v-data-table
              :headers="headersPorcentajes"
              :items="itemsPorcentajes"
              class="elevation-5"
              :style="'width: 435px'"
              dense
              hide-default-footer
            >
              <template v-slot:top>
                <v-row>
                  <v-col cols="12" md="8" class="pb-1 pt-2">
                    <span class="primary--text py-0 pl-2 font-weight-medium"
                      >Porcentajes de descuento (editable)</span
                    ></v-col
                  >
                  <v-col cols="12" md="4" align="end" class="py-2 pr-6 px-n5">
                    <v-btn
                      color="primary"
                      class="to-right"
                      small
                      @click="
                        itemsPorcentajes.push({
                          rgEscalaDesde: 0,
                          rgEscalaHasta: 0,
                          rgEscalaPorcentaje: 0,
                        })
                      "
                    >
                      Nuevo
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:[`item.rgEscalaDesde`]="{ item }">
                <v-text-field
                  v-model="item.rgEscalaDesde"
                  persistent-hint
                  height="2px"
                  rounded
                  type="text"
                  :rules="
                    item.rgEscalaDesde > item.rgEscalaHasta ||
                    item.rgEscalaDesde < 0 ||
                    item.rgEscalaDesde > 12
                      ? ['Incorrecto']
                      : []
                  "
                  v-mask="'##'"
                ></v-text-field>
              </template>
              <template v-slot:[`item.rgEscalaHasta`]="{ item }">
                <v-text-field
                  v-model="item.rgEscalaHasta"
                  type="text"
                  rounded
                  height="2px"
                  width="100px"
                  persistent-hint
                  :rules="
                    item.rgEscalaHasta < item.rgEscalaDesde ||
                    item.rgEscalaHasta < 0 ||
                    item.rgEscalaHasta > 12
                      ? ['Incorrecto']
                      : []
                  "
                  v-mask="'##'"
                ></v-text-field>
              </template>
              <template v-slot:[`item.rgEscalaPorcentaje`]="{ item }">
                <v-text-field
                  v-model="item.rgEscalaPorcentaje"
                  type="text"
                  rounded
                  suffix="%"
                  height="2px"
                  width="100px"
                  persistent-hint
                  :rules="
                    item.rgEscalaPorcentaje <= 0 ||
                    item.rgEscalaPorcentaje > 100
                      ? ['Incorrecto']
                      : []
                  "
                  v-mask="'##'"
                ></v-text-field>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      size="20"
                      v-on="on"
                      @click="
                        itemsPorcentajes = itemsPorcentajes.filter(
                          (x) => x !== item
                        )
                      "
                    >
                      {{ deleteIcon }}
                    </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeAndReload"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid || saving"
          form="form"
          color="primary"
          :loading="btnIsLoadingRegla"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";

export default {
  name: "EditReglasComerciales",
  props: {
    editRgItem: {
      type: Object,
    },
  },
  directives: { mask },
  data: () => ({
    isFormValid: false,
    infoIcon: enums.icons.SNB_INFO,
    formEditTitle: enums.titles.EDITAR_RG_COMERCIAL,
    newTitle: enums.titles.NUEVA_RG_COMERCIAL,
    deleteIcon: enums.icons.DELETE,
    periodoDesde: null,
    periodoHasta: null,
    rules: rules,
    rgNombre: "",
    rgDescrip: "",
    rgProcSelect: null,
    btnIsLoadingRegla: false,
    editIcon: enums.icons.EDIT,
    itemsPorcentajes: [],
    headersPorcentajes: [
      {
        text: "Desde",
        align: "left",
        sortable: false,
        value: "rgEscalaDesde",
      },
      {
        text: "Hasta",
        align: "left",
        sortable: false,
        value: "rgEscalaHasta",
      },
      {
        text: "Porcentaje",
        sortable: false,
        value: "rgEscalaPorcentaje",
      },
      {
        text: "Eliminar",
        value: "actions",
        sortable: false,
        width: "12%",
        align: "end",
      },
    ],
    variableSelect: null,
    disabledAcumulableSwitch: false,
    acumulable: false,
    rgProcCombo: [],
    parametros: "",
    descProcNombre: "",
    orden: null,
    acumula: false,
    rgVarId: null,
    listVariables: [],
    saving: false,
  }),
  created() {
    this.procRgSelect();
    setTimeout(() => {
      if (this.editRgItem) this.setRg();
      else this.newReglaComercial();
    }, 150);
  },
  methods: {
    ...mapActions({
      saveReglaComercial: "configuracion/saveReglaComercial",
      getReglasComercialesProc: "configuracion/getReglasComercialesProc",
      getVariablesReglasComercialesTaxo:
        "configuracion/getVariablesReglasComercialesTaxo",
      setAlert: "user/setAlert",
    }),
    async procRgSelect() {
      const response = await this.getReglasComercialesProc();
      this.rgProcCombo = response;
      this.checkPermiteAcumular(this.rgProcCombo);
    },
    resetParametro() {
      this.$refs.parametros?.reset();
    },
    checkPermiteAcumular(array) {
      const seleccionado = array.find((x) => x.id == this.rgProcSelect);
      if (seleccionado) this.permitirAcumular(seleccionado);
    },
    permitirAcumular(item) {
      if (item.rgProcPermitirAcumular === true)
        this.disabledAcumulableSwitch = false;
      else {
        this.disabledAcumulableSwitch = true;
        this.acumulable = false;
      }
    },
    setRg() {
      this.rgId = this.editRgItem.rgId;
      this.rgNombre = this.editRgItem.rgNombre;
      this.rgDescrip = this.editRgItem.rgDescrip;
      this.rgProcSelect = this.rgProcCombo.find(
        (x) => x.id == this.editRgItem.rgProcId
      );

      this.descProcNombre = this.editRgItem.rgProcNombre;
      this.parametros = this.editRgItem.parametros;
      this.periodoDesde = this.editRgItem.periodoVigenciaDesde;
      this.periodoHasta = this.editRgItem.periodoVigenciaHasta;
      this.orden = this.editRgItem.orden;
      this.acumulable = this.editRgItem.acumulable;
      this.variableSelect = this.editRgItem.rgVariableId;
      this.itemsPorcentajes = this.editRgItem.escalas;
      this.obtenerVariable();
    },
    async newReglaComercial() {
      this.formEditTitle = this.newTitle;
      this.itemsPorcentajes.push({});
    },
    async obtenerVariable() {
      //this.editRgItem.rgProcId
      //    ? this.editRgItem.rgProcId
      //    : (this.editRgItem.rgProcId = 0);
      const par = {
        procId: this.rgProcSelect.id,
        rgId: this.editRgItem ? this.editRgItem.rgId : 0,
      };
      const variable = await this.getVariablesReglasComercialesTaxo(par);
      this.listVariables = variable;
    },
    async saveRegComercial() {
      if (this.saving) return; // Evitar la ejecución duplicada si la función ya está en curso
      this.saving = true;
      this.btnIsLoadingRegla = true;
      const data = {
        rgId: this.editRgItem?.rgId,
        rgNombre: this.rgNombre,
        rgVarId: this.variableSelect ? this.variableSelect : null,
        rgDescrip: this.rgDescrip,
        rgProcId: this.rgProcSelect.id,
        parametros: this.parametros,
        periodoVigenciaDesde: this.periodoDesde,
        periodoVigenciaHasta: this.periodoHasta,
        orden: Number(this.orden),
        acumulable: this.acumulable,
        porcentajes: this.rgProcSelect.rgProcPermiteEscalarPorcentaje
          ? this.itemsPorcentajes
          : null,
      };
      try {
        const response = await this.saveReglaComercial(data);
        if (response.status === 200) {
          this.showDeleteModal = false;
          this.setAlert({ type: "success", message: "Guardado con éxito" });
          this.closeAndReload();
        }
      } catch (err) {}

      this.saving = false;
      this.btnIsLoadingRegla = false;
    },
    closeAndReload() {
      this.$emit("closeAndReload");
    },
  },
};
</script>

<style scoped>
.repeat input {
  border-color: red;
}
.repeat label {
  color: red;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;

  margin: 0;
}

::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
</style>
