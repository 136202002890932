<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" />

        <!-- Filtro -->
        <v-card class="mx-auto my-12 mt-2 mb-2" max-width="1200" outlined>
          <v-container>
            <v-row class="pr-4">
              <v-col
                cols="12"
                md="10"
                class="py-0"
                align="left"
                @click="showFilters = true"
              >
                <FiltersSelected
                  :filters="filtersApplied"
                  v-if="!showFilters"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                align="right"
                align-self="center"
                class="py-0"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="showFilters = !showFilters"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition mode="out-in">
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="loadReglasComerciales()"
              >
                <v-row>
                  <v-col cols="2" class="py-0">
                    <v-text-field
                      dense
                      outlined
                      clearable
                      v-model="periodo"
                      label="Período"
                      hint="Formato AAAAMM"
                      persistent-hint
                      type="text"
                      onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                      v-mask="'######'"
                      @keyup.enter="loadReglasComerciales()"
                      :rules="rules.periodoYyyyMm"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" class="py-0">
                    <v-autocomplete
                      v-model="variableFilter"
                      :items="variableCombo"
                      item-text="value"
                      item-value="id"
                      label="Variable"
                      outlined
                      clearable
                      dense
                      return-object
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="4"></v-col>
                  <v-col cols="2" align="end" class="text-right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          size="28"
                          @click="resetForm"
                        >
                          {{ clearFiltersIcon }}
                        </v-icon>
                      </template>
                      <span>Limpiar filtros</span>
                    </v-tooltip>
                    <v-btn
                      color="primary"
                      elevation="2"
                      small
                      :disabled="!isFormValid"
                      type="submit"
                      form="filters-form"
                    >
                      Aplicar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>

        <!-- Fin filtro -->
        <v-data-table
          :headers="headers"
          :items="reglasComercialesRegistros"
          class="elevation-1"
          :search="search"
          item-key="rgId"
          show-expand
          :expanded.sync="expanded"
          :loading="isLoading"
        >
          <template v-slot:[`item.acumulable`]="{ item }">
            <v-icon
              v-if="item.acumulable"
              small
              color="primary"
              class="d-flex justify-center"
            >
              {{ checkIcon }}
            </v-icon>
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="8">
                  <v-text-field
                    v-model="search"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="2" align="end" v-if="canVariable">
                  <v-btn color="primary" @click="openVariables()">
                    Variables
                  </v-btn>
                </v-col>
                <v-col cols="2" align="end" v-if="canCreate">
                  <v-btn color="primary" @click="openModal()" class="to-right">
                    Nuevo
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="16"
                  v-on="on"
                  @click="openModalLogRg(item.rgId)"
                >
                  {{ seeIcon }}
                </v-icon>
              </template>
              <span>Ver log de regla comercial</span>
            </v-tooltip>
            <v-tooltip left v-if="canEdit">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="16"
                  v-on="on"
                  @click="openModal(item)"
                >
                  {{ editIcon }}
                </v-icon>
              </template>
              <span>Editar regla comercial</span>
            </v-tooltip>
            <v-tooltip left v-if="canDelete">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="16"
                  v-on="on"
                  @click="deleteRg(item)"
                >
                  {{ deleteIcon }}
                </v-icon>
              </template>
              <span>Eliminar regla comercial</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click="handleExpansion(item, isExpanded)"
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  >{{
                    isExpanded ? "mdi-account-check" : "mdi-account"
                  }}</v-icon
                >
              </template>
              <span>Usuarios</span>
            </v-tooltip>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <strong>Fecha alta:</strong>
              {{ item.fecAlta }}
              <strong style="padding-left: 80px">Usuario alta:</strong>
              {{ item.usuAlta }}
              <strong style="padding-left: 80px">Fecha modificación:</strong>
              {{ item.fecModi }}
              <strong style="padding-left: 80px">Usuario modificación:</strong>
              {{ item.usuModi }}
            </td>
          </template>
        </v-data-table>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-5 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="openModalLog"
      v-model="openModalLog"
      max-width="90%"
      @keydown.esc="closeModalLog"
      persistent
    >
      <LogReglasComerciales
        :reglaId="reglaId"
        @closeModalLog="closeModalLog"
      ></LogReglasComerciales
    ></v-dialog>
    <div v-if="openModalEdit">
      <v-dialog
        v-model="openModalEdit"
        max-width="50%"
        @keydown.esc="closeAndReload"
        persistent
      >
        <EditReglasComerciales
          :editRgItem="editRgItem"
          @closeAndReload="closeAndReload"
        ></EditReglasComerciales>
      </v-dialog>
    </div>
    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'30%'"
      :isLoading="loadingBtnDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import FiltersSelected from "@/components/shared/FiltersSelected";
import DeleteDialog from "@/components/shared/DeleteDialog";
import EditReglasComerciales from "@/components/modules/cuotas/configuracion/EditReglasComerciales.vue";
import LogReglasComerciales from "@/components/modules/cuotas/configuracion/LogReglasComerciales.vue";
import GoBackBtn from "@/components/shared/GoBackBtn";
import { mask } from "vue-the-mask";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  components: {
    PageHeader,
    DeleteDialog,
    EditReglasComerciales,
    LogReglasComerciales,
    FiltersSelected,
    GoBackBtn,
    Ayuda,
  },
  name: "ReglasComerciales",
  directives: { mask },
  data: (vm) => ({
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    seeIcon: enums.icons.SEE,
    searchIcon: enums.icons.SEARCH,
    checkIcon: enums.icons.CHECK_OUTLINE,
    search: "",
    isLoading: false,
    showDeleteModal: false,
    idToDelete: null,
    showExpand: false,
    isFormValid: false,
    variableFilter: null,
    loadingBtnDelete: false,
    showHelp: false,
    showIcon: true,
    optionCode: enums.webSiteOptions.REGLAS_COMERCIALES,
    routeToGo: "DevengamientosCobranzas",
    title: enums.titles.REGLAS_COMERCIALES,
    titleDelete: "¿Eliminar regla comercial?",
    rules: rules,
    filtersApplied: [],
    variableCombo: [],
    reglasComercialesRegistros: [],
    editRgItem: {},
    cambioRcItems: null,
    headers: [
      {
        text: "Nombre",
        value: "rgNombre",
        sortable: true,
      },
      {
        text: "Descripción",
        value: "rgDescrip",
        sortable: false,
      },
      {
        text: "Procedimientos de reglas comerciales",
        value: "rgProcNombre",
        sortable: false,
      },
      {
        text: "Parámetros",
        value: "parametros",
        sortable: false,
      },
      {
        text: "Orden",
        value: "orden",
        sortable: false,
      },
      {
        text: "Período desde",
        value: "periodoVigenciaDesde",
        sortable: false,
      },
      {
        text: "Período hasta",
        value: "periodoVigenciaHasta",
        sortable: false,
      },
      {
        text: "Acumulable",
        value: "acumulable",
        sortable: false,
      },

      { text: "Acciones", value: "actions", sortable: false, align: "end" },
      { text: "", value: "data-table-expand", align: "end" },
    ],
    allowedActions: null,
    canEdit: false,
    canDelete: false,
    canCreate: false,
    canVariable: false,
    showFilters: true,
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    openModalEdit: false,
    openModalLog: false,
    expanded: [],
    peridoActual: vm.formatDate(new Date().toISOString().substr(0, 10)),
    periodo: null,
    filtrosGuardados: {},
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false,
    });
    this.periodo = this.peridoActual;
  },
  async mounted() {
    //this.loadReglasComerciales();
    this.loadVariablesComerciales();
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
    // Load filters if coming back from another screen

    if (this.$store.state.filtersReglas != null) {
      this.periodo = this.$store.state.filtersReglas.periodo;
      this.variableFilter = this.$store.state.filtersReglas.variableFilter;

      // Clear the filters to avoid applying them again when navigating away
      // this.$store.state.filtersReglas = null;
      // Load data with applied filters
      //this.loadReglasComerciales();
    }
    this.loadReglasComerciales();
  },
  methods: {
    ...mapActions({
      getReglasComerciales: "configuracion/getReglasComerciales",
      getReglasComercialesByFilter:
        "configuracion/getReglasComercialesByFilter",
      deleteReglaComercial: "configuracion/deleteReglaComercial",
      getVariablesComerciales: "configuracion/getVariablesComerciales",
      setAlert: "user/setAlert",
    }),
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month] = date.split("-");
      return `${year}${month}`;
    },
    setPermisos() {
      this.allowedActions?.forEach((x) => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.NUEVA_REGLA_COMERCIAL:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_REGLA_COMERCIAL:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.ELIMINAR_REGLA_COMERCIAL:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions.VARIABLE_REGLA_COMERCIAL:
            this.canVariable = true;
            break;
          default:
            break;
        }
      });
    },
    customizeFiltersApplied() {
      this.filtersApplied = [];
      if (this.periodo) {
        this.filtersApplied.splice(0, 1, {
          key: "periodo",
          label: "Período",
          model: this.periodo,
        });
      }
      if (this.variableFilter) {
        this.filtersApplied.splice(1, 1, {
          key: "variableFilter",
          label: "Variable",
          model: this.variableFilter.value
            ? this.variableFilter.value
            : this.variableFilter,
        });
      }
    },
    resetForm() {
      this.$refs["filters-form"].reset();
    },
    async loadReglasComerciales() {
      this.isLoading = true;
      this.$store.state.filtersReglas = null;
      const params = {
        periodo: this.periodo !== "" || this.periodo ? this.periodo : null,
        rgVarId: this.variableFilter ? this.variableFilter.id : null,
      };
      this.filtrosGuardados = {
        periodo: this.periodo !== "" || this.periodo ? this.periodo : null,
        variableFilter: this.variableFilter ? this.variableFilter : null,
      };
      const data = await this.getReglasComercialesByFilter(params);
      this.customizeFiltersApplied();
      this.reglasComercialesRegistros = data;
      this.showFilters = false;
      this.isLoading = false;
    },
    async loadVariablesComerciales() {
      const variable = await this.getVariablesComerciales();
      this.variableCombo = variable;
    },
    deleteRg(item) {
      this.showDeleteModal = true;
      this.itemToDelete = item;
    },
    async confirmDelete() {
      this.loadingBtnDelete = true;
      try {
        const response = await this.deleteReglaComercial({
          rgId: this.itemToDelete.rgId,
        });
        if (response.status === 200) {
          this.showDeleteModal = false;
          this.setAlert({ type: "success", message: "Eliminado con éxito." });
          this.loadReglasComerciales();
        }
      } catch {}
      this.loadingBtnDelete = false;
    },
    seeLogRg(item) {
      this.openModalImpuestoConcep = true;
      this.conceptoObject = item;
    },

    openModal(item) {
      this.openModalEdit = true;
      this.editRgItem = item;
    },

    openModalLogRg(reglaId) {
      this.openModalLog = true;
      this.reglaId = reglaId;
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.loadReglasComerciales();
    },
    closeModalLog() {
      this.openModalLog = false;
    },
    openVariables() {
      this.$router.push({
        name: "VariablesComerciales",
        params: {},
        goBackName: "ReglasComerciales",
      });
      this.$store.state.filtersReglas = this.filtrosGuardados;
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
